<template>
    <div class="dialog-container" id="jobs">
      <transition name="el-fade-in" mode="out-in">
        <el-row style="display: flex; align-items: center; justify-content: center; width: 100%" v-if="loading == 'updateScreenLoading' " key="screen-loading">
          <lottie-player autoplay loop mode="normal" src="/lottie/packaging-source-loading-lottie.json" style="width: 320px"></lottie-player>
        </el-row>
        <el-row v-else key="content-loading">
          <div class="dialog-title">
            <div class="head-title">Create New Project<span class="yellow-dot">.</span></div>
            <el-button class="btn close" type="danger" @click="close()"> <i class="icon-xmark-solid"></i></el-button>
          </div>
          <div class="form">
            <div class="form-table">
              <el-row :gutter="24">
                <el-col class="input-container" :sm="4" :xs="12">
                  <div class="left">
                    <i class="icon-peoplegroup"></i>
                    <span>Customer</span>
                  </div>
                </el-col>
                <el-col class="input-container" :sm="8" :xs="12">
                  <el-select style="width: 100%; margin-top: 15px"  filterable  placeholder="Select" v-model="job.CustomerId" @change="getSampleList">
                      <el-option v-for="item in customerList" :key="item.ID" :label="item.CompanyName" :value="item.ID"> </el-option>
                  </el-select>
                  <span class="error-validation">{{ $errorMessage("CustomerId", validation) }}</span>
                </el-col>
              </el-row>
              <div class="line"></div>
              <div class="form">
                <el-row >
                  <div class="input-container-row">
                    <el-switch v-model="selectType" active-text="Product Name" inactive-text="Select Products"> </el-switch>
                  </div>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="8" class="input-container" v-if="!selectType">
                    <div class="text">Select Product</div>
                    <el-select placeholder="Select Product" filterable clearable v-if="sampleProductList" value-key="FromSKU" v-model="job.Product" @change="createDescription()">
                      <el-option v-for="product in sampleProductList" :key="product.FromSKU + '-' + product.StringRepresentation" :value="product" :label="product.Description + '  - SKU: ' + product.FromSKU"></el-option>
                    </el-select>
                    <span class="error-validation">{{ $errorMessage("Product.Title", validation) }}</span>
                  </el-col>

                  <el-col :sm="8" class="input-container" v-if="selectType">
                    <div class="text">Products Name</div>
                    <el-input v-model="job.Product.Title" placeholder="..." @change="createDescription()"></el-input>
                    <span class="error-validation">{{ $errorMessage("Product.Title", validation) }}</span>
                  </el-col>
                  <el-col :sm="16" class="input-container">
                    <div class="text">Description</div>
                    <el-input v-model="job.Product.Description" placeholder="..."></el-input>
                    <span class="error-validation">{{ $errorMessage("Description.Title", validation) }}</span>
                  </el-col>
                </el-row>
                <el-switch @change="resetDiameter()" v-model="job.Product.Diameter" inactive-text="W-L-H" active-text="Other"></el-switch>
                <el-row :gutter="24" v-if="!job.Product.Diameter">
                  <el-col class="input-container" :sm="8">
                    <div class="text">Width</div>
                    <el-input v-model="job.Product.Width" placeholder="..." @change="createDescription()" v-numeric>
                      <div slot="append">CM</div>
                    </el-input>
                    <span class="error-validation">{{ $errorMessage("Product.Width", validation) }}</span>
                  </el-col>
                  <el-col class="input-container" :sm="8">
                    <div class="text">Length</div>
                    <el-input v-model="job.Product.Length" placeholder="..." @change="createDescription()" v-numeric>
                      <div slot="append">CM</div>
                    </el-input>
                    <span class="error-validation">{{ $errorMessage("Product.Length", validation) }}</span>
                  </el-col>
                  <el-col class="input-container" :sm="8">
                    <div class="text">Height</div>
                    <el-input v-model="job.Product.Height" placeholder="..." @change="createDescription()" v-numeric>
                      <div slot="append">CM</div>
                    </el-input>
                    <span class="error-validation">{{ $errorMessage("Product.Height", validation) }}</span>
                  </el-col>
                </el-row>
                <el-row :gutter="24" v-if="job.Product.Diameter">
                  <el-col class="input-container" :sm="8">
                    <div class="text">Other</div>
                    <el-input placeholder="..." class="input-with-select" v-model="job.Product.DiameterValue" @change="createDescription()" v-numeric>
                      <el-select v-model="job.Product.Unit" slot="append" class="append-diameter" placeholder="..." clearable @change="createDescription()" @clear="job.Product.Unit == null">
                        <el-option v-for="(item, index) in getEnums.find((x) => x.Title == 'Unit').Enums" :key="item.Key + index" :label="item.DisplayName" :value="item.Key"></el-option>
                      </el-select>
                    </el-input>
                    <span class="error-validation">{{ $errorMessage("Product.DiameterValue", validation) }}</span>
                  </el-col>
                </el-row>
                <template >
                  <el-row :gutter="24">
                    <el-col class="input-container" :sm="8">
                      <div class="text">Print</div>
                      <el-input v-model="job.Product.Print" placeholder="..." class="select"> </el-input>
                      <span class="error-validation">{{ $errorMessage("Product.Print", validation) }}</span>
                    </el-col>
                    <el-col class="input-container" :sm="8">
                      <div class="text">Alternative Print Options</div>
                      <el-select v-model="selectedPrint" @change="updateAlternativePrint" placeholder="Select" class="select">
                        <el-option v-for="(alternativePrints, alternativePrintIndex) in PrintAlternatives" :key="alternativePrintIndex + '-print'" :label="alternativePrints.AlternativePrint" :value="alternativePrints.AlternativePrint"> </el-option>
                      </el-select>
                      <span class="error-validation">{{ $errorMessage("Product.Print", validation) }}</span>
                    </el-col>
                  </el-row>
                  <el-row :gutter="24">
                    <el-col class="input-container" :sm="8">
                      <div class="text">Quality</div>
                      <el-input v-model="job.Product.Quality" placeholder="..." class="select"> </el-input>
                      <span class="error-validation">{{ $errorMessage("Product.Quality", validation) }}</span>
                    </el-col>
                      <el-col class="input-container" :sm="8">
                      <div class="text">Type</div>
                      <el-input v-model="job.Product.Type" placeholder="..." class="select"> </el-input>
                      <span class="error-validation">{{ $errorMessage("Product.Type", validation) }}</span>
                    </el-col>
                  </el-row>
                  <el-row :gutter="24">
                    <el-col class="input-container" :sm="8">
                      <div class="text">Unit Price</div>
                      <el-input v-model="job.Product.UnitPrice" placeholder="..." class="select" v-numeric> </el-input>
                      <span class="error-validation">{{ $errorMessage("Product.UnitPrice", validation) }}</span>
                    </el-col>
                    <el-col class="input-container" :sm="8">
                      <div class="text">Quantity</div>
                      <el-input v-model="job.Product.Quantity" placeholder="..." class="select" v-numeric > </el-input>
                      <span class="error-validation">{{ $errorMessage("Product.Quantity", validation) }}</span>
                    </el-col>
                  </el-row>
                </template>
              </div>
              <el-row :gutter="24">
                <!-- <el-col class="input-container" :sm="4" :xs="12">
                  <div class="left">
                    <i class="icon-boxes"></i>
                    <span>Product</span>
                  </div>
                </el-col>
                <el-col class="input-container" :sm="8" :xs="12">
                  <el-select placeholder="Select Product" filterable clearable v-if="sampleProductList" value-key="FromSKU" :disabled="job.CustomerId==null" v-model="job.ProductSKU" @change="changePrice">
                      <el-option v-for="product in sampleProductList" :key="product.FromSKU + '-' + product.StringRepresentation" :value="product.FromSKU" :label="product.Description + '  - SKU: ' + product.FromSKU"></el-option>
                  </el-select>
                  <span class="error-validation">{{ $errorMessage("ProductSKU", validation) }}</span>
                </el-col> -->
                <!-- <div class="input-container-row">
                  <el-switch v-model="selectType" active-text="Product Name" inactive-text="Select Products" @change="resetForm"> </el-switch>
                </div> -->
                </el-row>
              <!-- <div class="line"></div> -->
              <!-- <el-row :gutter="24">
                <el-col class="input-container" :sm="4" :xs="12">
                  <div class="left">
                    <i class="icon-pallet"></i>
                    <span>Quantity</span>
                  </div>
                </el-col>
                <el-col class="input-container" :sm="8" :xs="12">
                  <div class="content">
                      <el-input v-model="job.Quantity" placeholder="..."  class="name-input"> </el-input>
                  </div>
                  <span class="error-validation">{{ $errorMessage("Quantity", validation) }}</span>
                </el-col>
              </el-row>
              <div class="line"></div>
              <el-row :gutter="24">
                <el-col class="input-container" :sm="4" :xs="12">
                  <div class="left">
                    <i class="price-tag"></i>
                    <span>Unit Price</span>
                  </div>
                </el-col>
                <el-col class="input-container" :sm="8" :xs="12">
                  <div class="content">
                      <el-input v-model="job.UnitPrice" placeholder="..."  class="name-input"> </el-input>
                  </div>
                  <span class="error-validation">{{ $errorMessage("UnitPrice", validation) }}</span>
                </el-col>
              </el-row> -->
              <!-- <div class="line"></div>

              <el-row :gutter="24" >
                <el-col class="input-container" :sm="4" :xs="12">
                  <div class="left">
                    <i class="icon-peoplearrows"></i>
                    <span>Supplier</span>
                  </div>
                </el-col>
                <el-col class="input-container" :sm="8" :xs="12">
                  <el-select filterable v-model="job.SupplierId" placeholder="Select">
                    <el-option v-for="(item, index) in suppliersList.filter((x) => x.IsDeactivated != true && x.SupplierType==0)" :key="index" :value="item.ID" :label="item.Title"> </el-option>
                  </el-select>
                </el-col>
              </el-row> -->
              <div class="line" ></div>
              
              <el-row :gutter="24">
                <el-col class="input-container" :sm="4" :xs="12">
                  <div class="left">
                    <i class="icon-calendarplus"></i>
                    <span>Starting Date</span>
                  </div>
                </el-col>
                <el-col class="input-container" :sm="8" :xs="12">
                  <div class="content">
                    <el-date-picker format="dd-MM-yyyy" :clearable="false" prefix-icon="icon-calendarplus"  v-model="job.StartingDate"  type="date" placeholder="..."></el-date-picker>
                  </div>
                </el-col>
              </el-row>
              <div class="line"></div>

              <el-row :gutter="24">
                <el-col class="input-container" :sm="4" :xs="12">
                  <div class="left">
                    <i class="icon-hourglass"></i>
                    <span>Timeline</span>
                  </div>
                </el-col>
                <el-col class="input-container" :sm="8" :xs="12">
                  <div class="content">
                    <el-date-picker format="dd-MM-yyyy" prefix-icon="icon-calendarplus"  v-model="job.TimeLine"  type="date" placeholder="..."></el-date-picker>
                  </div>
                </el-col>
              </el-row>
              <div class="line"></div>

              <el-row :gutter="24">
                <el-col class="input-container" :sm="4">
                  <div class="left">
                    <i class="icon-usertie"></i>
                    <span>Product Manager</span>
                  </div>
                </el-col>
                <el-col class="input-container" :sm="8" :xs="12">
                  <el-select v-model="job.ProductManagerId" placeholder="Select" >
                    <el-option v-for="(item, index) in getUserList.filter((x) => (x.Role == 10 || x.Role == 3) && x.IsDeactivated == false)" :key="index" :value="item.ID" :label="item.Title" > </el-option>
                  </el-select>
                  <span class="error-validation">{{ $errorMessage("ProductManagerId", validation) }}</span>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="dialog-footer footerbutton">
            <el-button class="btn" type="danger" @click="close"> <i class="icon-xmark-solid"></i> Cancel</el-button>
            <el-button class="btn" type="primary"  @click="createJob"  :loading="loading == 'saveLoading'" :icon="'el-icon-plus'"> Add New Job</el-button>
          </div>
        </el-row>
        
      </transition>
    </div>
  </template>
  
  <script>
  export default {
    props: ["id"],
    name: "add-job",
    data() {
      return {
        PrintAlternatives: [
          {
            AlternativePrint: " Customer Design ",
          },
          {
            AlternativePrint: " 1 Colour ",
          },
          {
            AlternativePrint: " 2 Colour ",
          },
        ],
        job: {
            CustomerId:null,
            Product: {
              Diameter: false,
              DiameterValue: 0,
              Width: null,
              Height: 0,
              Length: 0,
              Print: null,
              Quality: null,
              UnitPrice: 0,
              Quantity: 0,
              Title: null,
              Description: null,
              ID: null,
              SelectType: false,
              Type: null,
            },
            SupplierId:null,
            StartingDate:new Date(),
            TimeLine:null,
            Quantity:null,
            UnitPrice:null,
            ProductManagerId:null,
            SupplierUnitPrice:null
        },
        selectedPrint: null,
        selectType:false,
        loading: false,
        timeout: null,
      };
    },
    async created() {
        await Promise.all([this.$store.dispatch("getCustomersList", { PageSize: 9999999, Page: 1, SearchTerm: null }),this.$store.dispatch("getSuppliersList", { Page: 1, PageSize: 999999, SearchTerm: null }),this.$store.dispatch("getUserList", { Page: 1, PageSize: 999999, SearchTerm: null })]);
        this.job.ProductManagerId=this.user.ID
    },
    methods: {
        checkNull(){
          if(this.job.Product.Width===""){
            this.job.Product.Width  =null;
          }
          if(this.job.Product.Length===""){
            this.job.Product.Length  =null;
          }
          if(this.job.Product.Height===""){
            this.job.Product.Height  =null;
          }
        },
        async getSampleList() {
            var customer= this.customerList.find((x)=> x.ID==this.job.CustomerId);
            var accountNo = customer.AccountNo;
            await this.$store.dispatch("getProductSamplesList", { Page: 1, PageSize: 999999, SearchTerm: null, RealProducts: true, AccountNo: accountNo });
        },
        async createJob(){

          this.checkNull()

          console.log(this.job)
          if (this.selectType){
            this.job.Product.FromSKU=null
            this.job.Product.ID=null
          }

          this.timeout = setTimeout(async () => {
            this.loading = 'saveLoading';
            var res = await this.$store.dispatch("addUpdateProject", this.job);
            if (!res.HasError) {
              await this.$store.dispatch("getProjectsList");
              this.close();
              this.loading=false;
            }else{
              this.loading=false;
            }
          }, 500);
        },
        close(id) {
          this.$emit("close", id);
        },
        async changePrice(){
          console.log(this.job.ProductSKU)
          var res = await this.$client.get("/Job/GetUnitPrice", {  params: { sku: this.job.ProductSKU} });
          console.log(res)
          if(res !=null){
            this.job.UnitPrice = res.data.Data
          }
          console.log(this.job.UnitPrice)
        },
        resetDiameter() {
          this.job.Product.DiameterValue = null;
          this.job.Product.Width = 0;
          this.job.Product.Height = 0;
          this.job.Product.Length = 0;
        },
        createDescription() {
          var x;
          if (this.job.Product.Unit == 0) {
            x = '"';
          } else if (this.job.Product.Unit == 1) {
            x = "oz";
          } else if (this.job.Product.Unit == 2) {
            x = "";
          } else if (this.job.Product.Unit == 3) {
            x = "cc";
          } else {
            x = "";
          }
          if(x != ""){
              x = x + " "; 
          }
          if (this.job.Product.Diameter ) {
            this.job.Product.Description = "Bespoke " + this.emptyIfNull(this.job.Product.DiameterValue) + x + this.job.Product.Title;
          } else {
            this.job.Product.Description = "Bespoke " + this.emptyIfNull(this.job.Product.Width) + "x" + this.emptyIfNull(this.job.Product.Length) +(this.job.Product.Height ? "x" + this.job.Product.Height: "") + "cm " + this.job.Product.Title;
          }
        },
        updateAlternativePrint() {
          this.job.Product.Print = this.selectedPrint;
          this.selectedPrint = null;
        },
        emptyIfNull(val) {
          return val || "";
        },
    },
    computed: {
      user() {
        return this.$store.getters.getUser;
      },
      getEnums() {
        return this.$store.getters.getEnums;
      },
      customerList() {
        return this.$store.getters.getCustomersList;
      },
      sampleProductList() {
        console.log(this.$store.getters.getProductSamplesList)
        return this.$store.getters.getProductSamplesList;
      },
      getAllCountries() {
        return this.$store.getters.getAllCountries;
      },
      validation() {
        return this.$store.getters.getProjectsValidationErrors;
      },
      suppliersList() {
        return this.$store.getters.getSuppliersList;
      },
      getUserList() {
        return this.$store.getters.getUserList;
      },
    }
  };
  </script>
  
  <style></style>
  